import env from '@frontend/env';
import { ThemeProvider } from '@frontend/design-system';
import { App } from './app';
import { ErrorBoundary } from './components/error-boundary';
import { DebugModeProvider } from './providers/debug-provider';
import { NotificationManagerProvider } from './providers/notification-manager';

const Root = () => {
  console.log('Version: ', env.VERSION);
  return (
    <ThemeProvider includeEmotionTheme skipAnimation={false}>
      <ErrorBoundary>
        <DebugModeProvider>
          <NotificationManagerProvider>
            <App />
          </NotificationManagerProvider>
        </DebugModeProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default Root;
