import { useCallPopStateSync, useCallPopStoreShallowStore } from '@frontend/pop';
import { WeavePopNotification } from '@frontend/types';
import { createContext, useContext, useState } from 'react';

/**
 * Referring to 'notifications' as everything that isn't a call pop. call pops are called 'pops' and the pop store manages them, but we expose the interaction here
 */
type ContextValue = {
  notifications: WeavePopNotification[];
  addNotification: (notification: WeavePopNotification) => void;
  removeNotification: (id: string) => void;
  updateNotification: (notification: WeavePopNotification) => void;
  currNotificationIndex: number;
  setCurrNotificationIndex: (index: number) => void;
  currNotification: WeavePopNotification | undefined;

  pops: ReturnType<typeof useCallPopStoreShallowStore>['notifications'];
  addPop: ReturnType<typeof useCallPopStoreShallowStore>['addNotification'];
  removePop: ReturnType<typeof useCallPopStoreShallowStore>['removeNotification'];
  setPops: ReturnType<typeof useCallPopStoreShallowStore>['setNotifications'];
  popOutlet: ReturnType<typeof useCallPopStoreShallowStore>['outlet'];
  setPopOutlet: ReturnType<typeof useCallPopStoreShallowStore>['setOutlet'];
  currPopIndex: ReturnType<typeof useCallPopStoreShallowStore>['activeIndex'];
  setCurrPopIndex: ReturnType<typeof useCallPopStoreShallowStore>['setActiveIndex'];
};
const NotificationManagerContext = createContext<ContextValue>(undefined as unknown as ContextValue);

export const NotificationManagerProvider = ({ children }: { children: React.ReactNode }) => {
  const [notifications, setNotifications] = useState<WeavePopNotification[]>([]);
  const [currNotificationIndex, setCurrNotificationIndex] = useState(0);
  const currNotification = notifications[currNotificationIndex];

  const addNotification = (notification: WeavePopNotification) => {
    setNotifications((prev) => [...prev, notification]);
  };

  const removeNotification = (id: string) => {
    setNotifications((prev) => {
      return prev.filter((n) => n.id !== id);
    });
  };

  const updateNotification = (notification: WeavePopNotification) => {
    setNotifications((prev) =>
      prev.map((n) =>
        n.id === notification.id && n.type === notification.type
          ? ({ ...n, ...notification } as typeof notification)
          : n
      )
    );
  };

  const {
    setOutlet: setPopOutlet,
    notifications: pops,
    setNotifications: setPops,
    addNotification: addPop,
    removeNotification: removePop,
    setActiveIndex: setCurrPopIndex,
    activeIndex: currPopIndex,
    outlet: popOutlet,
  } = useCallPopStateSync();

  const value = {
    notifications,
    addNotification,
    removeNotification,
    updateNotification,
    currNotificationIndex,
    currNotification,
    setCurrNotificationIndex,

    pops,
    addPop,
    removePop,
    setPops,
    currPopIndex,
    setCurrPopIndex,
    popOutlet,
    setPopOutlet,
  } satisfies ContextValue;

  return <NotificationManagerContext.Provider value={value}>{children}</NotificationManagerContext.Provider>;
};

export const useNotificationManager = () => {
  const context = useContext(NotificationManagerContext);
  if (!context) {
    throw new Error('useNotificationManager must be used within a NotificationManagerProvider');
  }
  return context;
};
