import { IPCRendererCallback, useShell } from '@frontend/shell-utils';
import { createContext, useContext, useEffect, useState } from 'react';

type ContextValue = { debugModeEnabled: boolean; disableDebugMode: () => void; enableDebugMode: () => void };
const DebugContext = createContext<ContextValue>(undefined as unknown as ContextValue);

export const DebugModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [debugModeEnabled, setDebugModeEnabled] = useState(false);

  const disableDebugMode = () => {
    setDebugModeEnabled(false);
  };

  const shell = useShell();
  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    const enableId = 'debug:enable-debug-mode' + Math.random();
    const enableCallback: IPCRendererCallback<'debug:enable-debug-mode'> = (_e) => {
      setDebugModeEnabled(true);
      console.info('Received Enable Debug Mode Event');
    };
    const disableId = 'debug:enable-debug-mode' + Math.random();
    const disableCallback: IPCRendererCallback<'debug:disable-debug-mode'> = (_e) => {
      setDebugModeEnabled(false);
      console.info('Received Enable Debug Mode Event');
    };
    shell.on?.('debug:enable-debug-mode', enableCallback, enableId);
    shell.on?.('debug:disable-debug-mode', disableCallback, disableId);
    return () => {
      if (enableCallback && enableId) {
        shell.off?.('debug:enable-debug-mode', enableCallback, enableId);
      }
      if (disableCallback && disableId) {
        shell.off?.('debug:disable-debug-mode', disableCallback, disableId);
      }
    };
  }, []);

  const value = {
    debugModeEnabled,
    disableDebugMode,
    enableDebugMode: () => setDebugModeEnabled(true),
  } satisfies ContextValue;

  return <DebugContext.Provider value={value}>{children}</DebugContext.Provider>;
};

export const useDebugMode = () => {
  const context = useContext(DebugContext);
  if (!context) {
    throw new Error('useDebugMode must be used within a DebugModeProvider');
  }
  return context;
};
