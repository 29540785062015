import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useShell } from '@frontend/shell-utils';

const ErrorDisplay = () => {
  const shell = useShell();
  const close = () => {
    return shell.emit?.('notifications:empty', undefined);
  };

  useEffect(() => {
    console.log('Closing Window from Error Boundary');
    setTimeout(() => {
      close();
    }, 50);
  }, []);
  //The user should never actually see this, but in case something happens, I'll render this so they can close the window
  return (
    <>
      <p>An Error Occurred</p>
      <button onClick={close}>Close This Popup</button>
    </>
  );
};

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return <Sentry.ErrorBoundary fallback={<ErrorDisplay />}>{children}</Sentry.ErrorBoundary>;
};
