import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import Root from './root';

const root = createRoot(document.getElementById('root')!);
root.render(
  <Suspense fallback='loading'>
    <Root />
  </Suspense>
);
